
        <template>
          <div class="vc-snippet-doc">
            <h1>更新日志</h1>
<ul>
<li>修订版本号：修复 bug，代码调整，功能优化。</li>
<li>次版本号：新增功组件或者新增功能。</li>
<li>主版本号：含有破坏性更新和新特性。</li>
</ul>
<h2>0.6.1</h2>
<p><code>2021-01-07</code></p>
<ul>
<li>新增 <code>ixu-color-picker</code>、<code>ixu-pan</code>组件</li>
</ul>
<h2>0.5.0</h2>
<p><code>2020-12-24</code></p>
<ul>
<li>新增<code>$log</code>插件</li>
</ul>
<h2>0.4.6</h2>
<p><code>2020-12-21</code></p>
<ul>
<li>富文本 colors、lineHeights、menus 可自定义</li>
</ul>
<h2>0.4.1</h2>
<p><code>2020-10-30</code></p>
<ul>
<li>新增<code>$storage</code>插件</li>
</ul>
<h2>0.3.7</h2>
<p><code>2020-10-24</code></p>
<ul>
<li>新增page-template组件</li>
</ul>
<h2>0.1.0</h2>
<p><code>2020-10-15</code></p>
<ul>
<li>🎉 第一个组件示例</li>
</ul>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            
           }
         }
       </script>
       <style lang='scss'>
         
       </style>